<template>
  <div class="sealCut">
    <input type="file" ref="filElem" style="position: absolute; clip: rect(0 0 0 0)" accept="image/png, image/jpeg, image/gif, image/jpg" @change="selectImg($event)" />
    <div v-if="imageUrl && imageUrl != ''" class="result">
      <div class="buttons">
        <el-button @click="choiceImg" type="text">重新上传</el-button>
        <span v-if="patternWidth"></span>
        <el-button v-if="patternWidth" @click="modifySeal" type="text">修改</el-button><span v-if="patternWidth"></span>
        <el-button v-if="patternWidth" @click="exampleShow = true" type="text">查看效果</el-button>
      </div>
      <div class="seal-box">
        <img :src="imageUrl" alt="" srcset="" />
      </div>
      <div class="size" v-if="patternWidth">
        {{ `尺寸（${patternWidth}mm &times;${patternHeight}mm)` }}
      </div>
    </div>
    <div v-else @click="choiceImg" class="nodata">
      <svg-icon class="icon" icon-class="yinjishangchuanpic" />
      <div class="tt">点击上传印章图片</div>
      <div class="hint">
        支持jpg/jpeg/png/bpm格式的图片，大小不超过5M，
        为保证印章的清晰，请上传高分辨率图片
      </div>
    </div>
    <el-dialog :close-on-click-modal='false' class="sealCut-dialog" @click.stop :visible.sync="cut_dialog_show" width="668px" @close="closeCutImg">
      <h2 slot="title">印章编辑器<span>（请沿印章边缘裁剪，并确保印章完整）</span></h2>
      <div class="cropper-box">
        <div class="cropper">
          <div class="handle">
            <div class="label"><span>* </span>实体章尺寸</div>
            <el-select v-if="certType == 2" v-model="widthHeight" placeholder="请选择" @change="cgSize">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-else v-model="widthHeight" placeholder="请选择" @change="cgSize">
              <el-option v-for="item in optionsPerson" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="sizeInput" v-if="widthHeight == 1">
            <el-input size="small" maxlength="3" onkeyup="value=value.replace(/[^\d]/g,'')" v-model="width" @blur="cgInputSize" placeholder="请输入">
              <span slot="suffix">mm</span>
            </el-input>
            &times;
            <el-input size="small" maxlength="3" onkeyup="value=value.replace(/[^\d]/g,'')" @blur="cgInputSize" v-model="height" placeholder="请输入">
              <span slot="suffix">mm</span>
            </el-input>
          </div>
          <div class="img-container">
            <img class="cropper_img" :src="cropper_img_url" @load="cropperInit()" ref="cropperImg" alt="" />
          </div>
          <div class="handle">
            <div class="rotateBox">
              <span>旋转</span>
              <el-input-number v-model="angle" class="rotate" @change="rotateTo" controls-position="right" size="small" :min="-360" :max="360"></el-input-number>
            </div>
            <div class="scaleBox">
              <span>缩放</span>
              <el-input-number v-model="ratio" @change="cropperZoom($accCalc.accAdd(ratio))" :precision="2" :step="0.01" :min="0.01" :max="2"></el-input-number>
            </div>
          </div>
        </div>
        <div class="preview-box">
          <div class="tt">印章预览</div>
          <div class="box">
            <div class="preview" ref="preview"></div>
          </div>
          <div class="hint">
            预览图仅供取章使用，确定位置后系统会对印章进行抠图处理
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="save"> 确 定 </el-button>
        <el-button @click="closeCutImg">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :append-to-body="true" :show-close="false" class="example-box" :visible.sync="exampleShow" top="1%" width="630px">
      <div class="bg"></div>
      <div class="close el-icon-close" @click="exampleShow=false"></div>
      <div :style="`width:${patternWidth/210*100}%;`" class="example">
        <img :src="imageUrl" alt="" srcset="">
        <div class="w" :class="{long:patternWidth/210*630>100}">{{patternWidth}}mm</div>
        <div class="h" :class="{long:patternHeight/297*891>100}">{{patternHeight}}mm</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
export default {
  props: ['title', 'imageUrl', 'certType'],
  data () {
    return {
      width: null,
      height: null,
      widthHeight: '20,20',
      angle: 0,
      ratio: 0.01,
      modifyRatio: 0.01,
      exampleShow: false,
      patternWidth: '',
      patternHeight: '',
      cropper_img_url: '',
      img_url: true,
      cut_dialog_show: false,
      myCropper: null,
      options: [
        {
          value: '42,42',
          label: '42mm x 42mm(默认)'
        },
        {
          value: '40,40',
          label: '40mm x 40mm'
        },
        {
          value: '45,30',
          label: '45mm x 30mm'
        },
        {
          value: '38,38',
          label: '38mm x 38mm'
        },
        {
          value: '1',
          label: '自定义'
        }
      ],
      optionsPerson: [
        {
          value: '20,20',
          label: '20mm x 20mm(默认)'
        },
        {
          value: '18,18',
          label: '18mm x 18mm'
        },
        {
          value: '38,38',
          label: '38mm x 38mm'
        },
        {
          value: '40,16',
          label: '40mm x 16mm'
        },
        {
          value: '1',
          label: '自定义'
        }
      ],
      aspectRatio: 1
    }
  },

  created () {
    if (this.certType == 2) {
      this.widthHeight = '42,42'
    } else {
      this.widthHeight = '20,20'
    }
  },
  watch: {
    certType: function (val) {
      if (val == 2) {
        this.widthHeight = '42,42'
      } else {
        this.widthHeight = '20,20'
      }
      this.cgSize()
    }
  },
  methods: {
    // 初始化
    cropperInit () {
      if (this.myCropper) {
        return
      }
      const self = this
      this.myCropper = new Cropper(this.$refs.cropperImg, {
        viewMode: 0,
        dragMode: 'move',
        initialAspectRatio: 1,
        aspectRatio: this.aspectRatio,
        preview: this.$refs.preview,
        autoCropArea: 0.8,
        zoomOnWheel: true,
        wheelZoomRatio: 0.05,
        rotatable: true,
        movable: true,
        center: true,
        cropBoxMovable: false,
        cropBoxResizable: false,
        toggleDragModeOnDblclick: false,
        minCanvasWidth: 200,
        minCropBoxWidth: 200,
        responsive: false,
        zoom: function (e) {
          self.ratio = e.detail.ratio
          self.modifyRatio = e.detail.ratio
        },

      })
    },
    cgSize () {
      if (!this.myCropper) {
        return
      }
      let aspectRatio = this.widthHeight.split(',')[0] / this.widthHeight.split(',')[1]
      this.myCropper.setAspectRatio(aspectRatio || 0.8)
      const cropBoxTop = (280 - 200 / aspectRatio) / 2
      const cropBoxLeft = (280 - 200) / 2
      this.myCropper.setCropBoxData({ width: 200, height: 200 / aspectRatio, top: cropBoxTop, left: cropBoxLeft })
    },
    cgInputSize () {
      this.width = this.width > 60 ? 60 : this.width < 18 ? 18 : this.width
      this.height = this.height > 60 ? 60 : this.height < 18 ? 18 : this.height
      let aspectRatio = this.width / this.height
      this.myCropper.setAspectRatio(aspectRatio || 1)
    },
    cropperZoom () {
      if (this.myCropper) {
        this.myCropper.zoomTo(this.ratio)
      }
    },
    rotateTo (angle = this.angle) {
      if (this.myCropper) {
        this.myCropper.rotateTo(angle)
      }
    },
    //选择图片
    selectImg (e) {
      let file = e.target.files[0]
      const fileMaxSize = 1024 * 1024 * 5
      if (file.size > fileMaxSize) {
        this.$message({
          message: '图片大小不可超过5M',
          type: 'error'
        })
        return false
      }
      if (!/\.(jpg|jpeg|png|JPG|PNG|bpm|BPM|JPEG)$/.test(e.target.value)) {
        this.$message({
          message: '图片类型要求：jpeg、jpg、png、bpm',
          type: 'error'
        })
        return false
      }
      //转化为blob
      let reader = new FileReader()
      reader.onload = e => {
        let data
        if (typeof e.target.result === 'object') {
          data = window.URL.createObjectURL(new Blob([e.target.result]))
        } else {
          data = e.target.result
        }
        this.angle = 0
        if (this.myCropper) {
          this.myCropper.reset()
          this.myCropper.replace(data)
        } else {
          this.cropper_img_url = data
        }
        this.cut_dialog_show = true
      }
      //转化为base64
      reader.readAsDataURL(file)
      e.target.value = ''
    },
    //   触发文件上传input
    choiceImg () {
      let element = this.$refs.filElem
      if (document.createEvent) {
        var evt = document.createEvent('MouseEvents')
        evt.initEvent('click', true, true)
        return element.dispatchEvent(evt)
      }
    },
    save () {
      let croppedCanvas = this.myCropper.getCroppedCanvas({
        imageSmoothingQuality: 'high',
        imageSmoothingEnabled: false
      })
      let imgBase64 = croppedCanvas.toDataURL('image/png', 1)
      let image = new Image()
      image.onload = () => {
        let w = image.width,
          h = image.height
        if (w > 320 || h > 320) {
          let ratio = w > 320 ? w / 320 : h / 320
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = w / ratio
          canvas.height = h / ratio
          ctx.drawImage(image, 0, 0, w / ratio, h / ratio)
          imgBase64 = canvas.toDataURL('image/png', 1)
        } else {
          let aspectRatio = w / h
          const canvas = document.createElement('canvas')
          const ctx = canvas.getContext('2d')
          canvas.width = 320
          canvas.height = 320 / aspectRatio
          ctx.drawImage(image, 0, 0, 320, 320 / aspectRatio)
          imgBase64 = canvas.toDataURL('image/png', 1)
        }
        let patternWidth = 0
        let patternHeight = 0
        if (this.widthHeight == 1) {
          patternWidth = this.patternWidth = this.width
          patternHeight = this.patternHeight = this.height
        } else {
          patternWidth = this.patternWidth = this.widthHeight.split(',')[0]
          patternHeight = this.patternHeight = this.widthHeight.split(',')[1]
        }

        this.cut_dialog_show = false
        this.$emit('save', imgBase64, { patternWidth, patternHeight })
      }
      image.src = imgBase64
    },
    closeCutImg () {
      this.cut_dialog_show = false
      this.ratio = 0.01
    },
    modifySeal () {
      this.cut_dialog_show = true
      this.ratio = this.modifyRatio
    }
  },
}
</script>

<style lang="scss" scoped>
.sealCut {
  width: 348px;
  height: 260px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #dee2e8;
  padding: 12px;
  line-height: normal;
  .result {
    .buttons {
      text-align: right;
      line-height: 18px;
      .el-button {
        font-size: 12px;
        padding: 0;
      }
      span {
        width: 1px;
        height: 14px;
        background: #dee2e8;
        display: inline-block;
        margin: 0 10px;
        vertical-align: middle;
      }
    }
    .seal-box {
      height: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 138px;
        max-height: 138px;
      }
    }
    .size {
      text-align: center;
      font-size: 12px;
      font-weight: 400;
      line-height: 28px;
      color: #b0b4bd;
    }
  }

  .nodata {
    text-align: center;
    cursor: pointer;
    .icon {
      color: #dee2e8;
      font-size: 60px;
      margin-bottom: 14px;
      margin-top: 32px;
    }
    .tt {
      font-size: 16px;
      color: #333333;
      margin-bottom: 8px;
      line-height: 20px;
    }
    .hint {
      line-height: 32px;
      font-size: 14px;
      color: #a7afc2;
    }
  }
}
::v-deep .sealCut-dialog {
  .el-dialog {
    padding: 0 20px;
    .el-dialog__header {
      padding: 0;
      line-height: 58px;
      border-bottom: 1px solid #e9e9e9;

      .el-dialog__title {
        font-size: 14px;
        color: #313d62;
        font-weight: bold;
      }
    }
    .el-dialog__body {
      padding: 0 0 20px;
      .sizetitle {
        font-size: 12px;
        color: #333333;
      }
      .cropper-box {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
        .cropper {
          width: 280px;

          .img-container {
            height: 280px;
            width: 280px;
            background-color: rgba($color: #000000, $alpha: 0.3);
          }
          .cropper_img {
            display: block;
          }
          .handle {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 18px;
            .label {
              width: 120px;
              span {
                color: #e34d59;
              }
            }
          }
          .sizeInput {
            width: 196px;
            margin-left: 84px;
            margin-bottom: 24px;
            border-radius: 2px;
            border: 1px solid #dee2e8;
            .el-input {
              width: 86px;
              border: 0 none;
              input {
                border: 0 none;
              }
            }
            .el-input__suffix-inner {
              span {
                color: #6c737b;
                line-height: 30px;
              }
            }
          }
        }
        .preview-box {
          width: 300px;
          padding: 65px 50px;

          .tt {
            font-size: 14px;
            color: #333333;
            margin-bottom: 16px;
            text-align: left;
            font-weight: 700;
          }
          .box {
            width: 200px;
            height: 200px;
            border: 1px solid #4994e2;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;
            .preview {
              width: 200px;
              height: 200px;
              overflow: hidden;
            }
          }
          .hint {
            color: #686f7e;
            line-height: 24px;
            text-align: center;
          }
        }
      }
    }
    .el-dialog__footer {
      .el-button {
        width: 64px;
        height: 34px;
        border-radius: 2px;
        padding: 0;
      }
    }
  }
}
.example-box {
  ::v-deep .el-dialog {
    margin-bottom: 0;
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 0;
      position: relative;
      .bg {
        width: 100%;
        height: 891px;
        background-image: url('~@/assets/image/lookPattern.png');
        display: block;
        background-size: 100% 100%;
      }
      .close {
        position: absolute;
        right: -60px;
        top: 0;
        display: inline-block;
        width: 40px;
        height: 40px;
        line-height: 42px;
        border-radius: 20px;
        background: #fff;
        font-size: 20px;
        cursor: pointer;
        color: #aeb5be;
        text-align: center;
        &:hover {
          color: $primaryColor;
        }
      }
      .example {
        position: absolute;
        top: 66.2%;
        left: 60%;
        border: 1px dashed #4873f6;
        background: rgba(239, 243, 255, 0.79);
        border-radius: 5px;
        img {
          width: 100%;
          display: block;
        }
        .w,
        .h {
          position: absolute;
          font-size: 11px;
          color: #fff;
          line-height: 16px;
          text-align: center;
          width: 48px;
          background: #4873f6;
          border-radius: 8px;
          &.long {
            &::before {
              content: '';
              display: block;
              position: absolute;
              left: -34px;
              top: 3px;
              background: url('~@/assets/image/jiantou.png') no-repeat;
              width: 30px;
              height: 10px;
            }
            &::after {
              content: '';
              display: block;
              position: absolute;
              background: url('~@/assets/image/jiantou.png') no-repeat;
              width: 30px;
              height: 10px;
              right: -34px;
              top: 3px;
              transform: rotate(180deg);
            }
          }
        }
        .w {
          top: -20px;
          left: 50%;
          margin-left: -24px;
        }
        .h {
          transform: rotate(90deg);
          right: -36px;
          top: 50%;
          margin-top: -8px;
        }
      }
    }
  }
}
.rotateBox {
  margin-right: 24px;
  .el-input-number--small {
    width: 70px;
    margin-left: 8px;
  }
  ::v-deep .el-input-number .el-input__inner {
    padding-left: 0;
    padding-right: 30px;
  }
}
.scaleBox {
  .el-input-number--small {
    width: 110px;
    margin-left: 8px;
  }
}
.handle {
  margin-bottom: 20px;
  ::v-deep .el-input__inner {
    border-color: #dcdfe6;
  }
}
.el-dialog__header {
  h2 {
    font-size: 14px;
    font-weight: bold;
    color: #333333;
  }
  span {
    font-size: 12px;
    color: #6c737b;
  }
}
</style>