<template>
  <div class="peyment">
    <div class="item">
      <div><span class="label">业务单名称</span><span>{{info.transactionEntity.name}}</span></div>
      <div><span class="label">业务类型</span><span>{{transactionType(info.applyFormEntity.businessType)}}</span></div>
      <div><span class="label">业务单号</span><span>{{info.transactionEntity.transactionNo}}</span></div>
    </div>
    <div class="item">
      <!-- <div><span class="label">证书年限（年）</span><span>{{info.applyFormEntity.certDuration / 365}}</span></div> -->
      <template v-if="[2].includes(info.applyFormEntity.businessType)">
        <div><span class="label">证书有效期</span><span> {{`${info.certStartDate.split(' ')[0]}至${info.certEndDate.split(' ')[0]}`}}</span></div>
        <div><span class="label">变更费用</span><span>{{info.transactionEntity.amountTotal}}元</span></div>
      </template>
      <template v-else>
        <div v-if="info.applyFormEntity.certDuration"><span class="label">证书年限（年）</span><span>{{info.applyFormEntity.certDuration / 365}}</span></div>
        <div v-else><span class="label">证书有效期</span><span> {{`${info.certStartDate.split(' ')[0]}至${info.certEndDate.split(' ')[0]}`}}</span></div>
        <div v-if="![9].includes(info.applyFormEntity.businessType)"><span class="label">证书费用</span><span>{{info.transactionEntity.serviceFeeYearly}}元/年</span></div>
      </template>

      <div v-if="([1,9].includes(info.applyFormEntity.businessType))"><span class="label">介质Ukey费用</span><span>{{info.transactionEntity.keyFee}}元</span></div>
    </div>
    <div class="item">
      <div>
        <span class="label">总计</span>
        <span v-if="info.transactionEntity.hasDiscount && info.transactionEntity.discountTotal < info.transactionEntity.amountTotal" class="price">
          <div class="dis">
            <span class="lb">折扣价</span>
            <span class="sum">¥<span>{{info.transactionEntity.discountTotal}}</span></span>（支持在线开票）
          </div>
          <div><del>原价¥ {{info.transactionEntity.amountTotal}}元</del></div>
        </span>
        <span class="sum" v-else>
          ¥ <span>{{info.transactionEntity.discountTotal}}</span>
        </span>
      </div>
    </div>
    <div class="qr-code-box" v-if="paymentMethod==='WeChat'">
      <div class="label">
        <img src="@/assets/icons/service/wx-peyment-icon.png" alt="">微信支付
      </div>
      <div class="qr-code">
        <div class="box">
          <div class="onPayment" v-if="onPayment">
            <i class="el-icon-success icon"></i>
            <div>付款中，请稍后…</div>
          </div>
          <img :src="qrCodeSrc" alt="">
        </div>
        <!-- <el-button type="text" @click="generatePayQrcode(true)">重新生成二维码</el-button> -->
      </div>
      <el-button type="text" class="changeMethod" v-if="info.applyFormEntity.certType==2" @click="paymentMethod='UnionPay'">更换支付方式</el-button>
    </div>
    <div class="unionPay" v-else>
      <el-button type="text" class="changeMethod" @click="paymentMethod='WeChat'">更换支付方式</el-button>
      <h4>
        <svg-icon icon-class="unionPay" />银行汇款
      </h4>
      <div class="remittanceAccount">
        <h3>请汇款至以下账户<i>（仅支持对公账户汇款）</i></h3>
        <ul>
          <li><span>账户名称：</span>山东豸信认证服务有限公司</li>
          <li><span>开户银行：</span>中国农业银行济南和平支行</li>
          <li><span>银行账号：</span>15158101040030989</li>
        </ul>
        <p>若遇到问题请联系客服，客服电话：400-030-2013。</p>
      </div>
    </div>
    <div class="payAttentionTo">
      <p>注意：付款成功后不支持退款申请，且不支持取消或修改业务单信息，请认真核实证书申请信息确保无误</p>
    </div>
  </div>
</template>

<script>
import { transactionType } from '@/utils/typeMatch'
export default {
  props: ['info'],

  data() {
    return {
      transactionType,
      qrCodeSrc: '',
      timeout: '',
      onPayment: false,
      businessType: {
        1: '新办',
        2: '变更',
        3: '续期',
        4: '注销',
        7: '解锁',
        9: '补办'
      },
      paymentMethod: 'WeChat'
    }
  },
  created() {
    this.generatePayQrcode()
  },
  methods: {
    generatePayQrcode(reset) {
      clearTimeout(this.timeout)
      let data = {
        transId: this.info.transactionEntity.id,
        payChannel: 1
      }
      if (reset) {
        data['reset'] = true
      }
      this.$api.generatePayQrcode(data).then(res => {
        this.qrCodeSrc = URL.createObjectURL(res.data)
        this.getTransIsPay()
      })
    },
    getTransIsPay() {
      this.$api
        .getTransIsPay({
          transId: this.info.transactionEntity.id
        })
        .then(res => {
          let code = res.code
          if (code === '0') {
            this.$parent.getTransactionDetail()
          } else if (code === '1') {
            this.timeout = setTimeout(() => {
              this.getTransIsPay()
            }, 3000)
          } else if (code === '-1' || code === '2') {
            this.generatePayQrcode()
          } else if (code === '-2') {
            this.onPayment = true
          } else {
            this.$message.error(res.msg)
          }
        })
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout)
  }
}
</script>

<style lang="scss" scoped>
.peyment {
  padding: 32px 14.3%;
  font-size: 14px;
  color: #303b50;
  line-height: 19px;
  .item {
    padding: 32px 0;
    border-bottom: 1px #dee2e8 solid;
    & > div {
      margin-bottom: 20px;
      &:last-child {
        margin: 0;
      }
      & > span {
        display: inline-block;
        vertical-align: top;
      }
      .label {
        width: 192px;
      }
    }
    .price {
      .dis {
        margin-bottom: 8px;
        .lb {
          font-size: 12px;
          color: #aeb5be;
          line-height: 16px;
          margin-right: 8px;
        }
      }
    }
    .sum {
      color: #fbb900;
      font-size: 16px;
      span {
        font-size: 24px;
      }
    }
  }
  .qr-code-box {
    padding: 46px 0 0;
    display: flex;
    text-align: left;
    position: relative;
    .label {
      width: 192px;
      img {
        width: 32px;
        height: 32px;
        margin-right: 12px;
      }
    }
    .qr-code {
      text-align: center;
      .onPayment {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(#000, 0.63);
        z-index: 99;
        padding-top: 32px;
        font-size: 12px;
        color: #fff;
        box-sizing: border-box;
        text-align: center;
        .icon {
          // color: $primaryColor;
          color: #52c41a;
          font-size: 32px;
          margin-bottom: 12px;
        }
      }
      .box {
        width: 128px;
        height: 128px;
        position: relative;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .el-button.el-button--text {
        padding: 12px 0 0;
      }
    }
  }
}
.changeMethod {
  font-size: 14px;
  color: #aeb5be;
  line-height: 19px;
  position: absolute;
  top: 32px;
  right: 0;
}
.unionPay {
  position: relative;
  padding: 44px 0 0;
  h4 {
    width: 114px;
    height: 38px;
    line-height: 38px;
    background: #ffffff;
    border-radius: 2px;
    border: 1px solid #1c67f3;
    font-size: 14px;
    color: #303b50;
    letter-spacing: 1px;
    font-weight: normal;
    padding: 0 14px;
    margin-bottom: 24px;
    background: url('~@/assets/image/personCenter/selected.png') 98px 23px
      no-repeat;
    background-size: 14px;
    cursor: pointer;
  }
  .svg-icon {
    margin-right: 8px;
  }
}
.remittanceAccount {
  width: 678px;
  height: 316px;
  background: #f1f4fc;
  padding: 30px;
  h3 {
    font-size: 16px;
    color: #303b50;
    line-height: 21px;
    letter-spacing: 1px;
    font-weight: normal;
  }
  i {
    color: #ff5846;
  }
  ul {
    margin: 24px 0;
    width: 614px;
    height: 137px;
    background: #ffffff;
    border-left: 2px solid #1c67f3;
    padding: 24px 22px;
  }
  li {
    font-size: 14px;
    color: #303b50;
    line-height: 19px;
    list-style: none;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  span {
    color: #6c737b;
  }
  p {
    font-size: 14px;
    color: #6c737b;
    line-height: 19px;
    margin-bottom: 8px;
  }
}
.payAttentionTo {
  padding: 26px 0 112px;
  color: #6d7278;
  font-size: 14px;
}
</style>